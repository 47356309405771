/**
 * This file is part of Analytikal.
 *
 * (c) 1 Giant Leap Holding BV
 *
 * For the full copyright and license information, please view the LICENSE file that was distributed with this source code.
 */
import { PhaseType } from '~/src/Domain/Engagement/PhaseType'
import t from '~/src/Infrastructure/Shared/Translation/t'

export default function usePhaseTypeTranslation(type: PhaseType) {
  const types: Record<PhaseType, string> = {
    [PhaseType.PLANNING]: t('engagements.phase_type.planning'),
    [PhaseType.INTERIM]: t('engagements.phase_type.interim'),
    [PhaseType.YEAR_END]: t('engagements.phase_type.year_end'),
    [PhaseType.OTHER]: t('engagements.phase_type.other'),
  }

  return types[type]
}
